<template>
  <div class="container">
    <div class="d-flex my-4">
      <h1 class="page-title mb-0">Settings</h1>
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>
